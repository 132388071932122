<template>
    <div class="wraper" v-if="detail">
        <Header :isShow="isShow" />
        <div class="banner">
            <!-- <img src="@/assets/images/service_detail_banner.png" alt="" /> -->
        </div>
        <div class="content max_big_box" ref="header" >
             <!-- <div class="title max_small_box">
                <p >{{detail.name}}</p>
            </div> -->
            <div class="content_first max_small_box">
                <div class="img" v-if="detail" data-aos="fade-right">
                    <img v-if="detail.image" :src="imgUrl + detail.image" />
                </div>
                <div class="text" v-if="detail" data-aos="fade-left">
                    <p class="title">{{detail.name}}</p>
                    <!-- <p class="desc">{{serviceDetail.intro}}</p> -->
                    <div v-if="detail.detail" class="desc" v-html="detail.detail"></div>
                    <p class="online" id="kefu_button" @click="changeModal">在线咨询</p>
                </div>
            </div>
            <!-- <div class="line"></div> -->
            <p class="detail_title max_small_box">服务介绍</p>
            
            <!-- </div> -->
            <!-- <div class="detail max_small_box"  v-html="detail.detail"></div> -->
            <!-- 详情 -->
            <div class="detail_box" v-if="detail">
                <div class="detail max_small_box">
                    <div class="list">
                        <p class="title">产品清单</p>
                        <div class="desc">
                            <a-table class="max_small_box" style="margin-top: 50px;scrollbar-width: 66px;" :columns="columnsOne" :data-source="table_data" :pagination="false" rowKey="id" :scroll="{ x: 1700 , y: 500 }">
                                <template v-slot:num="slotProps">{{
                                    slotProps.index + 1
                                }}</template>
                            </a-table>
                            <!-- <div class="pagination max_small_box">
                                <a-pagination :default-current="page" :total="last_page" @change="handleChangePage" />
                            </div> -->
                        </div>
                    </div> 
                    <div class="list">
                        <p class="title">服务流程</p>
                        <div class="desc">
                            <img :src="imgUrl + '/uploads/20210608/f218bcc9e12626e8f2ce022e8fa3ae3a.jpg'" alt="">
                        </div>
                    </div> 
                    <!-- <div class="list" v-for="(item, index) in detail.content" :key="index">
                        <p class="title">{{item.title}}</p>
                        <div class="desc" v-html="item.desc"></div>
                    </div>  -->
                </div>
            </div>
            <!-- <div class="line"></div> -->
        </div>
       
    </div>
</template>

<script>
import { onUnmounted, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import * as api from '../../utils/api';
import { get, post } from '../../utils/request';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import FloatingFrame from '../../components/floatingFrame/FloatingFrame';

//页面滚动处理函数
const ScrollEffect = () => {

    const isShow = ref()

    const handleScroll = (height) => {
        
        if(document.documentElement.scrollTop >= height){
            isShow.value = 1
        }else{
            isShow.value = 2
        }
    }

    return { handleScroll, isShow }
}

//详情处理模块
const detailEffect = () => {
    //详情
    const detail = ref()
    
    //一的页数
    const page = ref(1)

    //一的总页数
    const last_page = ref()
    const service_id = ref()
    const columnsOne = [
        // {
        //     title: '质量技术服务大类代码',
        //     dataIndex: 'type_code',
        //     key: 'type_code',
        //     scopedSlots: { customRender: 'type_code' },
        //     align:'center',
        //     width:'110px',

        // },
        // {
        //     title: '质量技术服务大类名称',
        //     dataIndex: 'type_name',
        //     key: 'type_name',
        //     align:'center',
        //     width:'110px',
        // },
        // {
        //     title: '质量技术服务代码',
        //     dataIndex: 'quality_code',
        //     key: 'quality_code',
        //     align:'center',
        //     width:'100px',
        // },
        // {
        //     title: '质量技术服务名称',
        //     dataIndex: 'quality_name',
        //     key: 'quality_name',
        //     align:'center',
        //     width:'110px',
        // },
        // {
        //     title: '检测服务代码',
        //     dataIndex: 'service_code',
        //     key: 'service_code',
        //     align:'center',
        //     width:'80px',
        // },
        {
            title: "序号",
            slots: { customRender: "num" },
            align:'center',
            width:'60px',
        },
        {
            title: '检测服务名称',
            dataIndex: 'name',
            key: 'name',
            align:'center',
            width:'130px',
        },
        {
            title: '产品编码',
            dataIndex: 'product_id',
            key: 'product_id',
            align:'center',
            width:'130px',
        },
        {
            title: '行业分类',
            dataIndex: 'industry_type',
            key: 'industry_type',
            align:'center',
            width:'100px',
        },
        {
            title: '产品类别',
            dataIndex: 'product_type',
            key: 'product_type',
            align:'center',
            width:'90px',
        },
        {
            title: '产品内容',
            dataIndex: 'product',
            key: 'product',
            align:'center',
            width:'120px',
        },
        {
            title: '服务适用范围',
            dataIndex: 'apply_rank',
            key: 'apply_rank',
            align:'center',
            width:'130px',
        },
        {
            title: '产品简介(服务/检测依据)',
            dataIndex: 'detail',
            key: 'detail',
            width:'220px',
        },
        {
            title: '产品用途说明',
            dataIndex: 'description',
            key: 'description',
            width:'130px',
        },
        {
            title: '检测项目',
            dataIndex: 'test_item',
            key: 'test_item',
        },
        // {
        //     title: '样品要求',
        //     dataIndex: 'sample_request',
        //     key: 'sample_request',
        //     align:'center',
        //     width:'80px',
        // },
    ];
    
    const table_data = ref()

     //获取详情
    const getDetail = async (id) => {
        let postData = {
            service_id: id,
            // page: page.value,
        }
        try{
            const result = await post('/industry/get_service', postData)
            // console.log('服务详情', result)
            if(result.code == 1){
                detail.value = result.data
                table_data.value = result.data.table_content

                // last_page.value = result.data.total
            }else{
                // console.log('请求失败')
            }
        }catch(e) {
        
        }
    } 

    
    //更换页数
    const handleChangePage = (new_page, pageSize) =>{
        // console.log('new_page：'+new_page)
        // console.log('pageOne.value：'+page.value)
        page.value = new_page
        
        getDetail(service_id.value)
    }
    const changeModal = () =>{
        const newWindow = window.open('', '_blank', 'width=600,height=400');
        newWindow.document.write(`
            <html>
            <body style="margin:0;padding:0;text-align: center;">
                <p style="text-align: center;">扫码添加微信客服咨询</p>
                <img src="https://gddqt.com//uploads/20240910/045192d6720e118f6ae0c785c4e20c96.png" alt="Your Image" style="width:90%;height:90%;object-fit:contain;" />
            </body>
            </html>
        `);
    }
    return { detail, getDetail, columnsOne, table_data, page, last_page, handleChangePage, service_id, changeModal }
}

export default {
    name: 'ServiceDetail',
    components: { Header, Footer, FloatingFrame },
    setup() {
        const route = useRoute()
        //页面滚动处理函数
        const { 
            handleScroll, 
            isShow 
        } = ScrollEffect()

        //详情处理模块
        const { detail, getDetail, columnsOne, table_data, pageTwo, last_page, handleChangePage, service_id } = detailEffect()

        //获取滑块到顶部的距离
        const header = ref(null)

        onMounted(() => {
            service_id.value = route.params.id
            getDetail(route.params.id)  //获取详情
            document.documentElement.scrollTop = document.body.scrollTop =0;
            window.addEventListener('scroll', function () {
                if(header.value){
                    handleScroll(header.value.offsetTop);
                }
            })
        });

        onUnmounted(() => {
            document.querySelector('body').setAttribute('style', 'background-color:#fff')
        })

        const { imgUrl }  = api

        return { imgUrl, isShow, header, detail, columnsOne, table_data, pageTwo, last_page, handleChangePage, service_id }
    }
}
</script>

<style lang="scss" scoped>
/* 轮播图 */
.banner{
    margin-top: 114px;
    border-bottom: #e5e5e5 solid 1px;
    @media screen and (max-width: 1200px){
        margin-top: 92px;
    }
    img{
        width: 100%;
        display: block;
    }
}
.content{
    margin-top: 30px;
    .content_first{
        display: flex;
        @media screen and (max-width: 1200px){
            display: block;
        }
        .img{
            width: 30%;
            @media screen and (max-width: 1200px){
                width: 100%;
            }
            img{
                width: 100%;
            }
        }
        .text{
            width: 60%;
            padding: 0 10%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            @media screen and (max-width: 1200px){
                width: auto;
                padding: 30px 30px;
            }
            .title{
                color: rgba(48, 90, 168, 100);
                font-size: 20px;
                font-weight: bold;
                @media screen and (max-width: 900px){
                    font-size: .16rem;
                }
            }
            .desc{
                color: rgba(16, 16, 16, 100);
                font-size: 16px;
                margin-top: 30px;
                line-height: 24px;
                text-align: justify;
                 @media screen and (max-width: 900px){
                    font-size: .14rem;
                }
            }
            .online{
                transition: all 0.36s ease;
                display: inline-block;
                color: white;
                font-size: 14px;
                padding: 10px 15px;
                background-color: #2F509E;
                margin-bottom: 20px;
                width: -webkit-max-content;
                width: -moz-max-content;
                width: max-content;
                cursor: pointer;
                border-radius: 5px;
                margin-top: 15px;
            }
        }
    }
    .title{
        @media screen and (max-width: 1200px){
            /* padding: 0 30px; */
        }
        p{
            color: rgba(16, 16, 16, 100);
            font-size: 20px;
            font-weight: bold;
        }
    }
    .line{
        width: 100%;
        height: 2px;
        background: rgba(187, 187, 187, 100);
        margin-top: 30px;
    }
    .detail{
        margin-top: 30px;
        line-height: 25px;
    }
    .line{
    height: 1px;
    background-color: #bbb;
    margin-top: 40px;
}
/* 详情 */
.detail_title{
    color: #305aa8;
    font-size: 20px;
    font-weight: bold;
    margin-top: 30px;
    padding-bottom: 20px;
    border-bottom: 2px solid #e6e6e6;
    @media screen and (max-width: 1200px){
        padding-left: 30px;
    }
    /* border-bottom: 1px solid linear-gradient(to bottom right, red, yellow); */
    /* background-image: linear-gradient(to bottom right, red, yellow); */
}
.detail_box{
    margin-top: 40px;
    @media screen and (max-width: 1200px){
        padding: 0 30px;
    }
}
.detail{
    /* display: flex; */
    flex-wrap: wrap;
    @media screen and (max-width: 900px){
        display: block;
    }
    .list{
        width: 80%;
        margin-right: 4%;
        margin-bottom: 50px;
        overflow-x: auto;
        &:nth-child(2n){
            margin-right: 0%;
        }
        @media screen and (max-width: 900px){
            width: 100%;
            margin-right: 0%;
        }
        .title{
            color: rgba(47, 80, 158, 100);
            font-size: 18px;
            font-weight: 600;
            /* &:after{
                content: "  ";
                width: 100%;
                height: 1px;
                display: block;
                background-color: #e2e2e2;
            } */
            @media screen and (max-width: 900px){
                font-size: .16rem;
            }
        }
        .desc{
            color: rgba(16, 16, 16, 100);
            font-size: 16px;
            margin-top: 14px;
            line-height: 24px;
            @media screen and (max-width: 900px){
                font-size: .14rem;
            }

            img{
                width: 70%;
            }
        }
        .imgs{
            display: flex;
            flex-wrap: wrap;
            margin-top: 40px;
            .img{
                width: 21%;
                object-fit: cover;
                margin-right: 5%;
                margin-bottom: 15px;
                &:nth-child(4n){
                    margin-right: 0%;
                }
                img{
                    width: 100%;
                }
            }
        }
        .list_img{
            width: 70%;
            @media screen and (max-width: 900px){
                width: 100%;
            }
        }
    }
}
}
.pagination{
    display: block;
    text-align: center;
    padding-top: 20px;
}

</style>